import React, { useContext, useEffect, useState } from 'react';
import { Link, Logo, NewsletterSignup, SocialLink } from 'components';
import * as styles from './Footer.module.scss';
import Section from './Section';
import { trackAmplitudeEvent } from '../utils';
import { userData } from '../utils/userDataToAmplitude';
import { AuthContext, TrackEventContext } from '../context';

export const Footer = ({ countryOfResidence, routes, inheritedClassname }) => {
  const { resource: user } = useContext(AuthContext) || {};
  const { intercomAttributes, intercomEmail } = useContext(TrackEventContext) || {};

  const aboutLinks = [
    routes?.about,
    routes?.team,
    // { to: '/', text: 'Careers' },
    // { to: '/', text: 'Partners' },
    routes?.contact,
  ];

  const learnMoreLinks = [
    routes?.retail,
    routes?.ipo,
    routes?.wholesale,
    routes?.blog,
    routes?.faq,
    routes?.whatIsEcf,
    routes?.raiseProcess,
    routes?.blogArchive,
  ];

  const legalLinks = [
    routes?.ia,
    routes?.disclosure,
    routes?.warning,
    routes?.directDebitPolicy,
    routes?.fairDealing,
    routes?.fsg,
  ];

  const socialLinks = routes?.socialLinks;

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(window.location.pathname);
  }, []);

  const handleClick = link => {
    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail,
        eventName: `Clicked Footer Link`,
        eventData: {
          footerLink: link,
          previousLink: currentUrl,
        },
        userData: user?.email ? userData(user) : intercomAttributes || {},
      });
    } catch (error) {
      console.error('Amplitude event Clicked Footer Link failed');
    }
  };

  return (
    <footer className={`${styles.footer} ${inheritedClassname || ''}`}>
      <div className={`${styles.footerColumns} wrapper`}>
        <div className={`${styles.footerCol} ${styles.footerCompany}`}>
          <Logo type="tertiaryDark" inheritedClassname={styles.logo} />
          <p>Equitise Pty Ltd</p>
          <p>
            NZBN: 9429041331231 <br />
            ABN: 75600053745
          </p>
          <p>Authorised and licensed by ASIC (AFSL #503190).</p>
        </div>
        <div className={`${styles.footerCol} ${styles.footerAbout}`}>
          <span className={styles.footerColTitle}>About</span>
          <nav>
            {aboutLinks.map(link => (
              <Link key={link.text} to={link.to}>
                <p className={styles.linkText} onClick={() => handleClick(link.text)}>
                  {link.text}
                </p>
              </Link>
            ))}
          </nav>
        </div>
        <div className={`${styles.footerCol} ${styles.footerLearn}`}>
          <span className={styles.footerColTitle}>Learn More</span>
          <nav>
            {learnMoreLinks.map(link => (
              <Link key={link.text} to={link.to}>
                <p className={styles.linkText} onClick={() => handleClick(link.text)}>
                  {link.text}
                </p>
              </Link>
            ))}
          </nav>
        </div>
        <div className={`${styles.footerCol} ${styles.footerLegal}`}>
          <span className={styles.footerColTitle}>Legal</span>
          <nav>
            {legalLinks.map(link => (
              <Link key={link.text} to={link.to}>
                <p className={styles.linkText} onClick={() => handleClick(link.text)}>
                  {link.text}
                </p>
              </Link>
            ))}
          </nav>
        </div>
        <div className={`${styles.footerCol} ${styles.footerNewsletterSocial}`}>
          <span className={styles.footerColTitle}>Connect With Us</span>
          {socialLinks && <FooterSocialLinks socialLinks={socialLinks} />}
          <span className={styles.footerColTitle}>Subscribe</span>
          <NewsletterSignup
            inheritedClassname={styles.newsletterSignupSubtext}
            countryOfResidence={countryOfResidence}
          />
        </div>
      </div>
      <Section className={styles.sitemap} containerClassName={styles.wrapper} as="div">
        <span className={styles.legal}>© {new Date().getFullYear()} Equitise Pty Ltd. All rights reserved.</span>
        <div className={styles.sitemapRight}>
          <nav className={styles.privacyTerms}>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-conditions">Terms of Service</Link>
          </nav>
        </div>
      </Section>
    </footer>
  );
};

const FooterSocialLinks = ({ socialLinks }) => (
  <nav className={styles.footerSocialLinks}>
    {socialLinks.map(socialLink => (
      <SocialLink key={socialLink.platform} type={socialLink.platform} url={socialLink.url.url} theme="primary" />
    ))}
  </nav>
);

// const FooterSocialLinks = () => (
//   <StaticQuery
//     query={socialLinksQuery}
//     render={data => {
//       const { social_links: socialLinks } = data.settings.data;
//       if (!socialLinks || socialLinks.length < 1) return null;
//       return (
//         <nav className="footer-social-links">
//           {socialLinks.map(socialLink => (
//             <SocialLink key={socialLink.platform} type={socialLink.platform} url={socialLink.url.url} theme="primary" />
//           ))}
//         </nav>
//       );
//     }}
//   />
// );
